<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-form>
          <b-row>
            <b-col
              md="6"
              xl="6"
            >
              <validation-provider
                #default="{ errors }"
                name="contacttype"
                rules="required"
              >
                <b-form-group
                  label-for="servicename"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> اسم الخدمة </label>
                  <v-select
                    v-model="newService.main_service"
                    label="name"
                    :reduce="(val) => val.id"
                    :options="optionService"
                    @input="getSubSevice(newService.main_service)"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              xl="6"
            >
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="cardNumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الخدمة الفرعية </label>
                  <v-select
                    v-model="newService.proposal_service"
                    label="name"
                       :multiple="true"
                    :reduce="(val) => val.id"
                    :options="optionSubServices"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              xl="6"
            >
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-input
                  id="textarea-default"
                  v-model="newService.notes"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
        <b-button
          variant="outline-primary"
          @click="addnewService"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="معلومات الخدمة">
          <!-- <div
            v-for="(serv, index) in personServices"
            :key="index"
          >
            <b-row>
              <b-col
                md="6"
                xl="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contacttype"
                  rules="required"
                >
                  <b-form-group
                    label-for="servicename"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> اسم الخدمة </label>
                    <v-select
                      v-model="serv.main_service"
                      label="name"
                      :reduce="(val) => val.id"
                      :options="optionService"
                      @input="getSubSevice(serv.main_service)"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="documentType"
                  rules="required"
                >
                  <b-form-group
                    label-for="cardNumber"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label> الخدمة الفرعية </label>
                    <v-select
                      v-model="serv.proposal_service"
                      label="name"
                      :reduce="(val) => val.id"
                      :options="optionSubServices"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="12"
                xl="2"
              >
                <b-form-group label-for="delimiter">
                  <label>الملاحظات</label>
                  <b-form-input
                    id="textarea-default"
                    v-model="serv.notes"
                    placeholder="الملاحظات"
                    rows="1"
                  />
                </b-form-group>
              </b-col>
              <b-col
                v-if="serv.id"
                class="border-Primary but"
                md="1"
                xl="3"
              >
                <b-button
                  variant="edit"
                  @click="UpdateBenSer(serv.id)"
                >
                  <span class="align-middle"> تعديل الخدمة</span>
                </b-button>
              </b-col>

              <b-col
                v-if="index != 0 && !serv.id"
                class="border-Primary but"
                md="2"
                xl="1"
              >
                <b-button
                  variant="purple"
                  @click="deleteService(index)"
                >
                  <span class="align-middle"> إلغاء</span>
                </b-button>
              </b-col>
            </b-row>
            <br>
            <br>
            <b-row />
          </div> -->
        
          <b-table
            responsive="sm"
            :items="personServices"
            :fields="fields"
          />
          <b-row>
            <b-col
              class="border-Primary mb-10"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="addservice()"
              >
                <span class="align-middle"> إضافة خدمة اخر</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from 'vue-select'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BTable,
  BModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import 'cleave.js/dist/addons/cleave-phone.us'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  name: 'AddBeneficiary',

  components: {
    BCardCode,
    BTable,
    BForm,
    BModal,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BRow,

    BCol,
  },

  props: {
    personServices: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      optionSubServices: [],
      optionService: [],
      newService: {},
      Mainservice: '',
      fields: [
        { key: 'main_service.name', label: 'الخدمة' },
        { key: 'proposal_service.name', label: ' الخدمة الفرعية ' },
        { key: 'notes', label: 'الملاحظة' },
      ],
    }
  },

  created() {
    this.getServiceType()
  },

  methods: {
    getSubSevice(id) {
      this.optionSubServices = []

      const url = `/api/v1/main_services/${id}/proposal_services`
      this.$http.get(url).then(res => {
        const subServices = res.data.data
        subServices.forEach(el => {
          this.optionSubServices.push({ name: el.name, id: el.id })
        })
      })
    },
    getData() {
      const url = `/api/v1/persons/${this.$route.params.id}`

      this.$http.get(url).then(res => {
        // console.log(res.data.data);
        this.personServices = res.data.data.personServices
      })
    },
    addnewService() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonServ()
            this.$refs['my-modal'].hide()
            this.resetModalNum()
          }
        })
      })
    },
    addPersonServ() {
      let data = null
      // console.log(this.personServices);

      data = {
        person_id: this.$route.params.id,
        proposal_service_id: this.newService.proposal_service,
        notes: this.newService.notes,
      }

      // let data = {
      //   proposal_services: this.services,
      // };
      // console.log(data);

      this.$http
        .post('/api/v1/pending_actions/person_proposal_services', data)
        .then(response => {
          // console.log(response);
          this.$swal({
            title: '',
            text: '  تم إضافة الخدمة   بنجاح بانتظار موافقة مدير المعلومات   ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          requestAnimationFrame(() => {
            this.$refs.simpleRules.reset()
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    UpdateBenSer(id) {
      let data = null

      this.personServices.forEach(el => {
        data = {
          notes: el.notes,
        }
      })
      // console.log(data);

      const url = `/api/v1/pending_actions/person_proposal_services/${id}`
      this.$http.put(url, data).then(res => {
        // console.log(res);
        this.$swal({
          title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    },
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        // console.log(proposal_services);
        proposal_services.forEach(el => {
          this.optionService.push({ name: el.name, id: el.id })
        })
      })
    },
    addservice() {
      this.$refs['my-modal'].show()
      // this.personServices.push({
      //   theService: '',
      //   notes: '',
      // })
      //
    },

    deleteService(index) {
      this.personServices.splice(index, 1)
    },
  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}

.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.align-left {
  left: 50px;
}
.border-Primary.mb-10.col-md-2.col-xl-1 {
    margin-bottom: 25px;
}
.but {
  top: 20px;
}
</style>
