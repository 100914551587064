<template>
  <div>
    <b-modal
      ref="my-modal"
      centered
      cancel-title="No"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
      <validation-observer ref="phonesimple">
        <b-form>
          <b-row>
            <b-col
              md="6"
              xl="6"
            >
              <validation-provider
                #default="{ errors }"
                name="contacttype"
                rules="required"
              >
                <b-form-group
                  label-for="contactType"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> وسبلة الاتصال</label>
                  <v-select
                    v-model="newNumber.contact_type"
                    label="name"
                    :options="contactType"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
            md="6"
              xl="6"
            >
          
              <validation-provider
                #default="{ errors }"
                name="cardnumber"
                rules="max:14|regex:^([0-9]+)$|required"
              >
                <b-form-group
                  label-for="cardnumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> الرقم</label>
                  <b-form-input
                    id="cardNumber"
                    v-model="newNumber.number"
                    class="form-control"
                    :raw="false"
                    placeholder=" 999 999 099"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="6"
              xl="6"
            >
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="newNumber.notes"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
            
            <br>

          </b-row>
        </b-form>
      </validation-observer>
      <template #modal-footer>
       
              <b-button
              
              v-if="newNumber.id"
                variant="edit"
                @click="updateBenNum(newNumber.id)"
              >
                <span class="align-middle"> تعديل الرقم</span>
              </b-button>
        
        <b-button
        v-else
          variant="outline-primary"
          @click="addnewNumber"
        >
          <span class="align-middle"> إضافة  </span>
        </b-button>
      </template>
    </b-modal>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="معلومات الاتصال">
        
          <b-table
            responsive="sm"
            :items="personNumber"
            :fields="fields"
            show-empty
            empty-text="No matching records found"
          >

            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
           
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item-button @click="showNumber(data.item)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">تعديل</span>
                </b-dropdown-item-button>

              </b-dropdown>
            </template>
          </b-table>

          <b-row>

            <b-col
              class="border-Primary"
              md="2"
              xl="1"
            >
              <b-button
                variant="purple"
                @click="addNumber()"
              >
                <span class="align-middle"> إضافة رقم اخر</span>
              </b-button>
            </b-col>

          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from 'vue-select'
import VueBarcode from 'vue-barcode'

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup,
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BTable,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { required, max } from '@validations'
import 'flatpickr/dist/flatpickr.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { personDetails } from '@/@core/mixins/personDetails.js'

export default {
  components: {
    BCardCode,
    required,
    BForm,
    BDropdownItemButton,
    BTable,
    BDropdown,
  BDropdownItem,
    BModal,
    vSelect,
    max,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    ToastificationContent,

    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    BFormTextarea,

    BRow,

    BCol,
  },
  mixins: [personDetails],

  props: {
    personNumber: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newNumber: {},
      contactType: [],
      fields: [
        { key: 'contact_type.name', label: 'وسبلة الاتصال' },
        { key: 'number', label: '  الرقم ' },
        { key: 'notes', label: 'الملاحظة' },
        { key: 'actions', label: 'الخيارات' },
        
      ],
    }
  },

  created() {
    this.getAlldrowpDown()
  },

  methods: {
    addnewNumber() {
      return new Promise((resolve, reject) => {
        this.$refs.phonesimple.validate().then(success => {
          if (success) {
            // this.personServices.push(this.newService)
            this.addPersonNum()
            this.$refs['my-modal'].hide()
          
          }
        })
      })
    },
    addPersonNum(i) {
      let data = null
      // console.log(this.personNumber);

      data = {
        person_id: this.$route.params.id,
        number: this.newNumber.number,
        notes: this.newNumber.notes,
        contact_type: this.newNumber.contact_type,
      }

      // let data = {
      //   proposal_services: this.services,
      // };
      // console.log(data);

      this.$http
        .post('/api/v1/pending_actions/contact_details', data)
        .then(response => {
          // console.log(response);
          this.newNumber.number=''
          this.newNumber.notes=''
          this.newNumber.contact_type=''
          this.$swal({
            title: '',
            text: '  تم إضافة الرقم   بنجاح بانتظار موافقة مدير المعلومات   ',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          requestAnimationFrame(() => {
            this.$refs.simpleRules.reset()
          })
        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `${error.response.data.message}`,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    getAlldrowpDown() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const contactType = res.data.data.contact_types
        contactType.forEach(el => {
          this.contactType.push({ name: el.name, id: el.id })
        })
      })
    },
    showNumber(item){
      this.$refs['my-modal'].show()
      this.newNumber=JSON.parse(JSON.stringify(item));
    },
    updateBenNum(id) {
      let data = null
      // console.log("f", this.personNumber);
      this.personNumber.forEach(el => {
        data = {
          number: el.number,
          note: el.notes,
          contact_type: el.contact_type.id,
        }
      })
      // console.log(data);
      this.$http
        .put(`/api/v1/pending_actions/contact_details/${id}`, data)
        .then(res => {
          // console.log(res);

          this.$swal({
            title: 'تم طلب التعديل بانتظار موافقة مدير المعلومات',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
    },
    addNumber() {
      this.$refs['my-modal'].show()
      this.newNumber.number=''
          this.newNumber.notes=''
          this.newNumber.contact_type=''
          this.newNumber.id=''
      // this.personNumber.push({
      //   connectionNumber: '',
      //   note: '',
      //   connectionType: '',
      // })
    },

    deleteNumber(index) {
      this.person.connect.splice(index, 1)
    },
  },
}
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.btn-gr {
  /* margin-left: 130px; */
  color: white;
  background-color: #00468e !important;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.align-left {
  left: 50px;
}

.but {
  top: 40px;
}
</style>
