<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="معلومات الفرد">
          <title></title>
          <b-row>
        
            <!-- credit card -->
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الاسم</label>

                <b-form-input v-model="person.first_name" placeholder="" />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الاب</label>

                <b-form-input v-model="person.father_name" placeholder="" />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الام</label>

                <b-form-input v-model="person.mother_name" placeholder="" />
              </b-form-group>
            </b-col>

            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> النسبة</label>

                <b-form-input v-model="person.last_name" placeholder="" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> صلة القرابى</label>
                <v-select
                  v-model="person.family_relationship"
                  label="name"
                  :options="optionRelationship"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الجنس</label>
                <v-select v-model="person.gender" label="name" :options="optiongender" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label>الجنسية</label>
                <v-select
                  v-model="person.nationality"
                  label="name"
                  :options="optionNationality"
                />
                <!-- <b-form-input v-model="person.nationality" placeholder="" /> -->
              </b-form-group>
            </b-col>
               <b-col md="6" xl="2">
              <validation-provider
                #default="{ errors }"
                name="studylevel"
                rules="required"
              >
                <b-form-group label-for="" >
                  <label>  المرض</label>
                  <v-select
                    v-model="person.disease"
                      :multiple="true"
                    :reduce="(val) => val.id"
                    label="name"
                    :options="optionDiseases"
                  />
                </b-form-group>
             
              </validation-provider>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> العمل الحالي</label>
                <v-select
                  v-model="person.current_work"
                  label="name"
                  :options="optionJobs"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> العمل السابق</label>
                <v-select v-model="person.last_work" label="name" :options="optionJobs" />

                <!-- <b-form-input v-model="person.lastWork" placeholder="" /> -->
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الحالة الاجتماعية</label>

                <v-select
                  v-model="person.martial_status"
                  label="name"
                  :options="MartialState"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="4">
              <b-form-group label-for="number">
                <label> تاريخ الميلاد</label>
                <b-form-datepicker
                  id="example-datepicker5"
                  v-model="person.birth_date"
                  class="mb-1"
                />
                <!-- <b-form-input v-model="person.dateOfBirth" placeholder="" /> -->
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> مكان الولادة</label>

                <b-form-input v-model="person.birth_place" placeholder="" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="2">
              <b-form-group label-for="number">
                <label> الملاحظات </label>

                <b-form-input v-model="person.note" placeholder="" />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="educational_attainment_id">
                <label> التحصيل العلمي </label>

                <v-select
                  v-model="person.educational_attainment"
                  label="name"
                  placeholder=""
                  :options="optionStudy"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" xl="3">
              <b-form-group label-for="number">
                <label> حالة التعليم </label>
                <v-select
                  v-model="person.educational_status"
                  label="name"
                  :options="EducationalState"
                />
                <!-- <b-form-input v-model="person.educationalStatus" placeholder="" /> -->
              </b-form-group>
            </b-col>

            <b-col md="3" xl="3">
              <b-form-group label-for="delimiter">
                <label>الملاحظات</label>
                <b-form-input
                  id="textarea-default"
                  v-model="person.weaknote"
                  placeholder="الملاحظات"
                  rows="1"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <b-col class="border-Primary" md="12" xl="4">
              <b-button variant="edit" @click="UpdateBenInfo">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="align-middle"> تعديل</span>
              </b-button>
            </b-col>
          </div>
          <!-- <b-card-code title="نقاط الضعف ">
               <b-row v-for="(Weak, index) in personWeak" :key="index">
                <b-col md="6" xl="4">
                  <b-form-group label-for="cardNumber">
                    <label> نقاط ضعف  </label>
                  <b-form-input
                      v-model="Weak.weak"
                      
                    />
                  </b-form-group>
                </b-col>
               
                <b-col md="12" xl="4">
                  <b-form-group label-for="delimiter">
                    <label>الملاحظات</label>
                    <b-form-input
                      id="textarea-default"
                      v-model="Weak.weaknote"
                      placeholder="الملاحظات"
                      rows="1"
                    />
                  </b-form-group>
                </b-col>
                <b-col class="border-Primary" md="4" xl="2">
                  <b-button variant="purple" @click="deleteDocument(index)">
                    <span class="align-middle"> إلغاء</span>
                  </b-button>
                </b-col>
              </b-row>
             </b-card-code> -->
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
import VueBarcode from "vue-barcode";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";

import {
  BFormDatepicker,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BForm,
  BButton,
  BImg,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
// import '@core/scss/vue/libs/vue-flatpicker.scss';
import "flatpickr/dist/flatpickr.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "add-beneficiary",

  components: {
    BCardCode,
    ToastificationContent,
    BForm,
    Treeselect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,

    barcode: VueBarcode,
    BImg,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BRow,

    BCol,
  },
  props: {
    person: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      weak: [],

      optionJobs: [],
      contactType: [],

      optionWeakness: [],
      EducationalState: [],
      RegistrationStatuses: [],
      optiongender: [],
      // optionState: [ "زائر",  "مسجل" ],
      optionNationality: [],
      optionStudy: [],
      optionRelationship: [],
      MartialState: [],
      weaknote: "",
    };
  },

  created() {
    this.getAlldrowpDown();
        this.getDiseases()
    // // this.getWeak();
  },

  methods: {
    UpdateBenInfo() {
      let data = {
        family_relationship_id: this.person.family_relationship.id,
        first_name: this.person.first_name,
        last_name: this.person.last_name,
        father_name: this.person.father_name,
        mother_name: this.person.mother_name,
        birth_date: this.person.birth_date,
        birth_place: this.person.birth_place,
        nationality_id: this.person.nationality.id,
        martial_status_id: this.person.martial_status.id,
        gender: this.person.gender.id,
        educational_attainment_id: this.person.educational_attainment.id,
        educational_status: this.person.educational_status.id,
        current_work_id: this.person.current_work.id,
        disease_id:this.person.disease,
        last_work_id: this.person.last_work.id,
        registration_status: this.person.registration_status.id,
        current_work_note: this.person.nowWork,
        last_work_note: this.person.nowNote,
        note: this.person.note,
      };
      //console.log(data);
      let url = `/api/v1/pending_actions/persons/${this.$route.params.id}`;
      this.$http.put(url, data).then((res) => {
        //console.log(res);
        this.$swal({
          title: "تم طلب التعديل بانتظار موافقة مدير المعلومات",
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      });
    },
   getDiseases() {
      this.optionDiseases = [];
      this.$http.get("/api/v1/diseases").then((res) => {
      console.log(res)
      this.optionDiseases = res.data.data;
       
      });
    },
    getWeak() {
      this.options = [];
      this.$http.get("/api/weakness").then((res) => {
        let subweak = res.data.data.subweakness;
        let Weak = res.data.data.weakness;
        Weak.forEach((el) => {
          let obj = {};
          obj.children = [];
          obj.label = el.name;
          obj.id = el.name;
          subweak.map((ele, index) => {
            if (el.id == ele.weakness_id) {
              obj.children.push({ label: subweak[index].name, id: ele.name });
            }
          });
          this.optionWeakness.push(obj);
        });
      });
    },

    getAlldrowpDown() {
      this.$http.get("/api/v1/beneficiaries_page/get_dropdown_data").then((res) => {
        let gender = res.data.data.genders;
        gender.forEach((el) => {
          this.optiongender.push({ name: el.name, id: el.id });
        });
        let countries = res.data.data.countries;
        countries.forEach((el) => {
          this.optionNationality.push({ name: el.name, id: el.id });
        });
        let educational_statuses = res.data.data.educational_statuses;
        educational_statuses.forEach((el) => {
          this.EducationalState.push({ name: el.name, id: el.id });
        });
        let martial_statuses = res.data.data.martial_statuses;
        martial_statuses.forEach((el) => {
          this.MartialState.push({ name: el.name, id: el.id });
        });
        let registration_statuses = res.data.data.registration_statuses;
        registration_statuses.forEach((el) => {
          this.RegistrationStatuses.push({ name: el.name, id: el.id });
        });
        let work_types = res.data.data.work_types;
        work_types.forEach((el) => {
          this.optionJobs.push({ name: el.name, id: el.id });
        });

        let educational_attainments = res.data.data.educational_attainments;
        educational_attainments.forEach((el) => {
          this.optionStudy.push({ name: el.name, id: el.id });
        });
        let family_relationships = res.data.data.family_relationships;
        family_relationships.forEach((el) => {
          this.optionRelationship.push({ name: el.name, id: el.id });
        });
      });
    },
  },
};
</script>

<style scoped>
label {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #00468e !important;
}
.align-left {
  left: 50px;
}
.btn-edit {
  color: white;
  width: 200px;
  background-color: #28c76f !important;
}
.but {
  top: 40px;
}
</style>
